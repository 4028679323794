<template>
  <v-app>
    <v-navigation-drawer app clipped fixed v-model="menu.shown">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">Menú principal</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-item-group>
        <v-btn
          v-if="!$store.getters.isLoggedIn"
          color="primary"
          text
          to="/login"
          block
          >Iniciar sesión</v-btn
        >
        <v-row v-else no-gutters class="pa-4">
          <v-btn icon to="/perfil">
            <v-avatar>
              <v-img
                alt="avatar_perfil"
                :src="$store.getters.getAvatar"
              ></v-img>
            </v-avatar>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="logout">Cerrar sesión</v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-btn icon to="/">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn
          icon
          @click.prevent="
            menu.historial = [];
            menu.historyIndex = 0;
            getMenu(1);
          "
        >
          <v-icon>mdi-restart</v-icon>
        </v-btn>
        <v-btn icon @click.prevent="getMenu(null, (back = true))">
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-item-group>
      <v-divider></v-divider>
      <v-list dense nav>
        <template v-if="menu.data.length > 0">
          <v-list-item
            link
            v-for="item in menu.data"
            :key="item.id"
            :to="item.tipo != 'LUGAR' ? '' : `/lugar/${item.id_old}`"
            @click.prevent="
              `${item.tipo != 'LUGAR' ? getMenu(Number(item.id_old)) : ''}`
            "
            color="primary"
          >
            <v-list-item-icon>
              <v-icon v-if="item.tipo === 'LUGAR'">mdi-church</v-icon>
              <v-icon v-else>mdi-arrow-right-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :title="item.nombre">{{
                item.nombre
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span v-else>Parece que no hay nada</span>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar clipped-left app light color="white">
      <v-btn icon @click="menu.shown = !menu.shown">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <router-link to="/">
        <img
          width="300"
          class="mx-2 d-none d-sm-block"
          contain
          :src="require('@/assets/logo.svg')"
          eager
          alt="logo"
        />
        <img
          width="42"
          class="mx-2 d-sm-none d-block"
          contain
          :src="require('@/assets/icono.svg')"
          eager
          alt="logo"
        />
      </router-link>

      <v-spacer></v-spacer>
      <strong class="d-none d-md-block grey--text mx-4"
        >La mayor base de datos de lugares cristianos de España</strong
      >

      <v-btn
        v-if="$store.getters.getToken === null"
        to="/login"
        color="primary"
        class="d-flex"
      >
        Iniciar Sesión
      </v-btn>

      <template v-else>
        <router-link
          to="/perfil"
          style="text-decoration: none"
          class="subtitle-1"
          >{{ this.$store.getters.getUsername }}</router-link
        >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mx-2" v-on="on" v-bind="attrs">
              <v-avatar>
                <v-img
                  contain
                  :src="$store.getters.getAvatar"
                  alt="avatar_perfil"
                ></v-img>
              </v-avatar>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link to="/" color="primary"
              ><v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon
              ><v-list-item-title>Inicio</v-list-item-title></v-list-item
            >
            <v-list-item link to="/perfil" color="primary"
              ><v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon
              ><v-list-item-title>Perfil</v-list-item-title></v-list-item
            >
            <v-list-item link @click="logout">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>Cerrar sesión</v-list-item-title></v-list-item
            >
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main v-scroll="onScroll">
      <v-container fluid class="ma-0 ma-md-2 pa-0 pa-md-2">
        <router-view class="main-content" :key="$route.fullPath"></router-view>
        <v-card v-if="$store.getters.showCookies" class="cookies-wrapper" dark>
          <v-row no-gutters align="center">
            <v-icon color="white" class="mr-4">mdi-cookie</v-icon>
            <p class="mb-0 mr-2">Esta página guarda cookies</p>
            <v-btn
              class="mx-1"
              color="error"
              @click.stop="cookiesConfirm(false)"
              >Rechazar</v-btn
            >
            <v-btn
              class="mx-1"
              color="primary"
              @click.stop="cookiesConfirm(true)"
              >Aceptar</v-btn
            >
          </v-row>
        </v-card>
      </v-container>
    </v-main>

    <v-footer inset class="mt-5 pl-0" dark>
      <v-row
        style="max-width: 1200px; margin: auto"
        class="pa-5 text-center"
        justify="space-around"
        align="center"
        no-gutters
      >
        <v-col>
          <v-dialog v-model="contact.show" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text dark v-bind="attrs" v-on="on">Contacto</v-btn>
            </template>
            <v-card light class="pa-5">
              <v-card-actions>
                <v-card-title>Formulario de contacto</v-card-title>
                <v-spacer></v-spacer>
                <v-icon @click="contact.show = false">mdi-close</v-icon>
              </v-card-actions>
              <v-form
                ref="contactForm"
                v-model="contact.validation"
                @submit.prevent="enviarCorreo"
              >
                <v-text-field
                  v-model="contact.nombre"
                  :rules="[contact.rules.req]"
                  filled
                  label="Su nombre:"
                  name="nombre"
                ></v-text-field>
                <v-text-field
                  v-model="contact.email"
                  :rules="[contact.rules.req]"
                  filled
                  label="Su correo:"
                  name="email"
                ></v-text-field>
                <v-text-field
                  v-model="contact.asunto"
                  :rules="[contact.rules.req]"
                  filled
                  label="Asunto:"
                  name="asunto"
                ></v-text-field>
                <v-textarea
                  :rules="[contact.rules.req, contact.rules.min]"
                  filled
                  label="Mensaje:"
                  name="mensaje"
                  v-model="contact.mensaje"
                  no-resize
                ></v-textarea>
                <v-btn type="submit" color="primary" block large>Enviar</v-btn>
              </v-form>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <v-btn text href="mailto:info@turismoyreligion.es"
            >info@turismoyreligion.com</v-btn
          >
        </v-col>
        <v-col>
          <v-dialog v-model="avisoLegal" width="unset" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text dark v-on="on" v-bind="attrs">Aviso legal</v-btn>
            </template>
            <v-card max-width="500px" width="100%">
              <v-row align="center" no-gutters>
                <v-card-title>Aviso legal</v-card-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="avisoLegal = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
              <v-divider></v-divider>
              <v-card-text
                >Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Explicabo rem dolor facilis ea repellat laborum vel minima
                nostrum quidem in? Sit labore illum obcaecati? A cum aperiam
                cumque, cupiditate, obcaecati molestiae praesentium accusamus
                eveniet neque, provident illo delectus eius magni voluptas quod
                iste minus iusto! Quae harum in ad eaque nisi voluptatum
                voluptate quibusdam minima? Soluta ut laboriosam omnis animi
                recusandae dolorem minus cum aliquam, aut cumque fugit nostrum
                mollitia eos, illum, quae reprehenderit modi ipsam nihil error
                sit hic laborum! Voluptate aperiam sunt laudantium nulla
                sapiente rerum dolor harum neque minus necessitatibus pariatur
                tempore, earum aliquam ratione fugiat alias modi voluptates?
                Placeat quis veniam laboriosam cumque nostrum delectus nam saepe
                sint, pariatur nesciunt consectetur optio id atque at earum
                sapiente quaerat doloribus tempora? Nam omnis nisi, distinctio
                impedit beatae consequuntur maiores culpa. Iste similique ullam
                unde reiciendis rem voluptates. Vel reprehenderit facilis quae
                praesentium voluptatum quaerat. Amet voluptatem magni iste odio
                id doloribus repellendus hic similique blanditiis modi,
                reprehenderit inventore quam illum, minus culpa animi porro,
                possimus beatae veniam qui architecto. Corporis esse cupiditate
                eum velit natus. Doloremque libero magnam sunt a eveniet
                corrupti expedita dolore alias. Hic repellendus id rem
                similique, tenetur illum debitis quam vitae est
                culpa?</v-card-text
              >
            </v-card>
          </v-dialog>
        </v-col>
        <v-col>
          <span>© {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-row>
    </v-footer>
    <Confirm ref="confirm" />
    <v-snackbar
      color="primary"
      @snack="snack(msg)"
      bottom
      timeout="3000"
      v-model="snackbar"
      >{{ snackbarMsg }}</v-snackbar
    >

    <v-fab-transition>
      <v-btn
        v-show="offsetTop > 100"
        small
        fixed
        light
        fab
        icon
        bottom
        right
        @click="$vuetify.goTo(0)"
        style="background: white"
      >
        <v-icon large color="primary">mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import axios from "redaxios";
import Confirm from "@/components/Confirm.vue";
import { req, min } from "@/utils/validationRules.js";

export default {
  name: "App",

  metaInfo() {
    return {
      title: "Turismo y Religión",
      meta: [
        { name: "description", content: "Turismo y Religión" },
        {
          name: "keywords",
          content:
            "turismo, religion, viajes, explorar, viajar, alojamiento, rutas, espiritu, vacaciones, cultura, dios, virgen, santos, españa",
        },
        { name: "theme-color", content: "#993366" },
      ],
    };
  },

  components: { Confirm },

  data: () => ({
    contact: {
      show: false,
      nombre: "",
      email: "",
      asunto: "",
      mensaje: "",
      rules: {
        req: req,
        min: (v) => min(v, 20),
      },
      validation: false,
    },
    menu: {
      shown: true,
      data: [],
      historial: [],
      historialIndex: -1,
    },
    snackbar: false,
    snackbarMsg: "hola",
    avisoLegal: false,
    offsetTop: 0,
  }),
  computed: {
    scrollProgress: function () {
      return Math.round(
        (this.offsetTop / (document.body.offsetHeight - window.innerHeight)) *
          100
      );
    },
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollingElement.scrollTop;
    },
    logout() {
      if (confirm(`¿Estás seguro de que quieres cerrar sesión?`)) {
        this.$store.dispatch("logout").then(() => {
          this.$router.push({ name: "Home" }).catch(() => {});
          this.$root.$emit("snack", "Se ha cerrado la sesión");
        });
      }
    },
    enviarCorreo() {
      this.$refs.contactForm.validate();
      let formData = new FormData();
      formData.append("nombre", this.contact.nombre);
      formData.append("asunto", this.contact.asunto);
      formData.append("correo", this.contact.email);
      formData.append("mensaje", this.contact.mensaje);
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/contact.php`,
        data: formData,
      })
        .then(() => {
          this.$refs.contactForm.resetValidation();
          this.contact.show = false;
          this.contact.nombre = "";
          this.contact.email = "";
          this.contact.asunto = "";
          this.contact.mensaje = "";
          this.$root.$emit("snack", "Tu correo se ha enviado con éxito");
        })
        .catch(() => {
          this.$emit("snack", "No se ha podido enviar tu correo");
        });
    },
    getMenu(parent_id, back = false) {
      if (!back) {
        this.menu.historial.push(parent_id);
        this.menu.historialIndex = this.menu.historial.length - 1;
      } else {
        this.menu.historialIndex > 0 ? this.menu.historialIndex-- : "";
        parent_id = this.menu.historial[this.menu.historialIndex];
        this.menu.historial = this.menu.historial.slice(
          0,
          this.menu.historialIndex + 1
        );
      }
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/menu.php`,
        headers: { "Content-Type": "application/json", charset: "utf-8" },
        params: {
          parent_id: parent_id,
        },
      })
        .then((response) => {
          this.menu.data = response.data.sort((a, b) =>
            a.tipo > b.tipo ? -1 : 1
          );
        })
        .catch((error) => console.error(error));
    },
    cookiesConfirm(val) {
      document.cookie = `cookiesConfirm=${val}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      this.$store.dispatch("showCookies", false);
      if (val) this.$root.$emit("snack", "Gracias por tu confirmación");
    },
  },
  mounted() {
    this.$store.dispatch("locateClient");

    this.getMenu(1);

    this.$root.confirm = this.$refs.confirm;
    this.$root.$on("snack", (msg) => {
      this.snackbar = true;
      this.snackbarMsg = msg;
    });
  },
};
</script>

<style lang="scss">
// @import url("//db.onlinewebfonts.com/c/a78cfad3beb089a6ce86d4e280fa270b?family=Calibri");

* {
  box-sizing: border-box;
}

html {
  // font-size: 18px;
}

.v-application {
  background: #f1f1f1 !important;
  // font-family: 'Calibri', sans-serif;
}
.main-content {
  max-width: 1600px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.no-romper-palabras {
  word-break: keep-all;
}
.cookies-wrapper {
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  padding: 32px;

  // background-color: white !important;
  color: white !important;
}
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #993366;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #bebebe;
}
</style>