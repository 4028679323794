import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/Home.vue"),
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login.vue"),
		beforeEnter: (to, from, next) => {
			store.getters.isLoggedIn ? router.push({ name: "Perfil" }) : next();
		},
	},
	{
		path: "/lugar/:id",
		name: "Lugar",
		component: () => import("@/views/Lugar.vue")
	},
	{
		path: "/perfil/:id_usuario?",
		name: "Perfil",
		component: () => import("@/views/Perfil.vue"),
		beforeEnter: (to, from, next) => {
			let id_usuario = to.params.id_usuario || store.getters.getUserId;
			id_usuario === null ? router.push({ name: "Login" }) : next();
		},
	},
	{
		path: "/busqueda",
		name: "Busqueda",
		component: () => import("@/views/Busqueda.vue"),
	},
	{
		path: "/recuperar",
		name: "Recuperacion",
		component: () => import("@/views/RecuperarClave.vue"),
		beforeEnter: (to, from, next) => {
			store.getters.isLoggedIn ? router.push({ name: "Perfil" }) : next();
		},
	},
	{
		path: "/error_404",
		name: 404,
		component: () => import("@/err/404.vue"),
	},
	{
		path: "*",
		name: "ERROR",
		redirect: "/",
		// component: () => import("@/views/Error404.vue")
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	duplicateNavigationPolicy: "ignore",
});

router.beforeEach(async (to, from, next) => {
	const exp = store.getters.getExp;
	if (exp != null && exp < Date.now() / 1000) {
		await store.dispatch("logout");
		alert("La sesión ha expirado");
	}
	store.commit("addHistoryCount");
	next();
});

export default router;
