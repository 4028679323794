import Vue from "vue";

import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

Vue.component("cropper", () => import("vue-advanced-cropper"));

Vue.use(VueQuillEditor /* { default global options } */);
