import Vue from "vue";
import Vuex from "vuex";

import axios from "redaxios";

Vue.use(Vuex);

const decodeToken = (token) => JSON.parse(atob(token.split(".")[1]));

const readCookie = (name) => {
	var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
	return v ? v[2] : null;
};

export default new Vuex.Store({
	state: {
		token: localStorage.getItem("token") || null,
		position: {
			lat: 40.4167754,
			lng: -3.7037902,
		},
		hasPositionChanged: false,
		showCookies: !readCookie("cookiesConfirm"),
		markers: [],
		clickedMarker: null,
		zoomLevel: null,
		historyCount: 0,
	},
	getters: {
		getPosition: (state) => state.position,
		hasPositionChanged: (state) => state.hasPositionChanged,
		getToken: (state) => state.token,
		isLoggedIn: (state) => !!state.token,
		showCookies: (state) => state.showCookies,
		getHistoryCount: (state) => state.historyCount,
		getUserId: (state, getters) =>
			getters.isLoggedIn ? decodeToken(state.token).user_id : null,
		getUsername: (state, getters) =>
			getters.isLoggedIn ? decodeToken(state.token).nombre : null,
		getCorreo: (state, getters) =>
			getters.isLoggedIn ? decodeToken(state.token).correo : null,
		getAvatar: (state, getters) =>
			getters.isLoggedIn
				? `/static/avatar/${decodeToken(state.token).avatar}`
				: null,
		getPermisos: (state, getters) =>
			getters.isLoggedIn ? decodeToken(state.token).permisos : null,
		getExp: (state, getters) =>
			getters.isLoggedIn ? decodeToken(state.token).exp : null,
		getMarkers: (state) => state.markers,
		getClickedMarker: (state) => state.clickedMarker,
		getZoomLevel: (state) => state.zoomLevel,
	},
	mutations: {
		login(state, token) {
			state.token = token;
		},
		locateClient(state, pos) {
			state.position.lat = pos.coords.latitude;
			state.position.lng = pos.coords.longitude;
			state.hasPositionChanged = true;
		},
		logout(state) {
			state.token = null;
		},
		saveMarkers(state, markers) {
			return new Promise((resolve) => {
				state.markers = markers;
				resolve();
			});
		},
		saveClickedMarker(state, pos) {
			return new Promise((resolve) => {
				if (pos == null) state.clickedMarker = null;
				else
					state.clickedMarker = {
						lat: Number(pos.latitude),
						lng: Number(pos.longitude),
					};
				resolve();
			});
		},
		saveZoomLevel(state, { zoom }) {
			return new Promise((resolve) => {
				state.zoomLevel = zoom;
				resolve();
			});
		},
		showCookies(state, val) {
			return new Promise((resolve) => {
				state.showCookies = val;
				resolve();
			});
		},
		addHistoryCount(state) {
			state.historyCount++;
		}
	},
	actions: {
		login(context, { email, password }) {
			return new Promise((resolve, reject) => {
				axios({
					method: "post",
					url: `${process.env.VUE_APP_API_URL}/login.php`,
					data: {
						correo: email,
						contraseña: password,
					},
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
				})
					.then((response) => {
						const token = response.data.token;
						localStorage.setItem("token", token);
						context.commit("login", token);
						resolve(response);
					})
					.catch((error) => {
						console.error(error);
						reject(error);
					});
			});
		},
		register({ dispatch }, user) {
			return new Promise((resolve, reject) => {
				axios({
					method: "post",
					url: `${process.env.VUE_APP_API_URL}/register.php`,
					data: {
						correo: user.email,
						nombre: user.username,
						fnacimiento: user.nacimiento,
						password: user.password,
					},
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
				})
					.then(async (response) => {
						await dispatch("login", {
							email: user.email,
							password: user.password,
						});
						resolve(response);
					})
					.catch((err) => {
						try {
							localStorage.removeItem("token");
						} catch {
							//pass
						}
						reject(err);
					});
			});
		},
		logout({ commit }) {
			return new Promise((resolve) => {
				commit("logout");
				localStorage.removeItem("token");
				resolve();
			});
		},
		locateClient(context) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((pos) =>
					context.commit("locateClient", pos)
				);
			}
		},
		async getMarkers(context) {
			let { data } = await axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/get_markers.php`,
			});
			context.commit("saveMarkers", data);
		},
		async saveClickedMarker({ commit }, pos) {
			await commit("saveClickedMarker", pos);
		},
		async saveZoomLevel({ commit }, { zoom }) {
			await commit("saveZoomLevel", { zoom });
		},
		async showCookies({ commit }, val) {
			await commit("showCookies", val);
		},
		addHistoryCount({commit}) {
			commit("addHistoryCount")
		},
	},
});
