<template>
  <v-bottom-sheet
    class="text-center border-primary"
    height="200"
    persistent
    v-model="dialog"
  >
    <v-card>
      <v-row align="center" justify="center" class="px-4">
        <v-col cols="12" class="col-md-6">
          <v-row align="center">
            <v-card-title>Confirmación de contraseña</v-card-title>
            <small class="text-caption"
              >(Esta confirmación es obligatoria)</small
            >
          </v-row>
          <v-divider class="mb-4"></v-divider>
          <v-form ref="form" @submit.prevent="confirm">
            <v-alert type="error" dense v-if="error"
              >Los datos no son correctos</v-alert
            >
            <v-text-field
              full-width
              filled
              label="Tu contraseña actual"
              v-model="contraseña"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              :rules="[rules.req, rules.min]"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-btn block type="reset" color="error" @click="cancel"
                  >Cancelar</v-btn
                >
              </v-col>
              <v-col>
                <v-btn block type="submit" color="primary">Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import axios from "redaxios";
import { req, min } from "@/utils/validationRules.js";

export default {
  data() {
    return {
      dialog: false,
      contraseña: "",
      data: {},
      show: false,
      resolve: null,
      error: false,
      reject: null,

      rules: {
        req: req,
        min: (v) => min(v, 8),
      },
    };
  },
  methods: {
    open(data) {
      this.data = data;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      let formData = new FormData();
      formData.append("token", this.$store.getters.getToken);
      formData.append("clave", this.contraseña);
      formData.append(
        "nuevaClave",
        this.data.nuevaClave != "" ? this.data.nuevaClave : this.contraseña
      );
      formData.append("nombre", this.data.nuevoNombre);
      formData.append("bio", this.data.nuevaBio);
      formData.append("avatar", this.data.nuevaFoto);

      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/usuario.php`,
        data: formData
      })
        .then(() => {
          this.resolve(true);
          this.error = false;
          this.dialog = false;
          this.resolve = null;
          this.reject = null;
          this.$store
            .dispatch("login", {
              email: this.$store.getters.getCorreo,
              password: this.contraseña,
            })
            .then(() =>
              this.$root.$emit("snack", "Se han realizado los cambios")
            );
        })
        .catch(() => {
          this.error = true;
          // if (err.response.status == 403) {
          //   let self = this;
          //   this.$store.dispatch("logout").then(() => {
          //     self.$root.$emit("snack", "La sesión ha caducado");
          //     self.reject(false);
          //     self.error = false;
          //     self.dialog = false;
          //     self.resolve = null;
          //     self.reject = null;
          //     self.$router.push({ name: "Login" });
          //   });
          // }
        })
        .finally(() => {
          this.contraseña = "";
        });
    },
    cancel() {
      this.$refs.form.reset();
      this.reject(true);
      this.error = false;
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>