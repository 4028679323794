export const min = (v, m) => (v.length >= m ? true : `Mínimo de ${m} carácteres`);
export const max = (v, m) => (v.length <= m ? true : `Máximo de ${m} carácteres`);

export const req = (v) => !!v || "Este campo es obligatorio";

export const containsNum = (v) => /\d/.test(v) || "Debe contener almenos un número" 

export const email = (v) => {
	const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return pattern.test(v) || "El email no es válido";
};
