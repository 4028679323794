import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueMeta from 'vue-meta'

import '@/utils/globalComponents'

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.use(VueMeta)
new Vue({
  router,
  vuetify,
  store,

  render: function(h) {
    return h(App);
  },
}).$mount("#app");
